export const LanguageCurrencyMap = new Map([
  ['en-US', 'USD'],
  ['en-GB', 'GBP'],
  ['en-GB-oxendict', 'GBP'],
  ['en-AU', 'AUD'],
]);

export const NUMBERS_ONLY_REGEX = /\d+/;
export const EMAIL_REGEX = /^(?:\s*)(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])(?:\s*)$/i; /* eslint-disable-line no-control-regex */
export const PHONE_REGEX = /^(?:\+)?(\(?[0-9]{5,}\)?)?[0-9_\- ()]{5,}(\s*(?:#|x\.?|ext?\.?|extension)\s*(\d+)?)?$/i;
export const NOT_EMPTY_REGEX = /^(?!\s*$).+/i;
export const WEBSITE_ADDRESS_REGEX = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/\S*)?(\?\S*)?(#.*)?$/i;
export const SUPPORT_PHONE_NUMBER = '+1 (866) 800-0004';
export const SUPPORT_PHONE_NUMBER_EXT = '+1 (866) 800-0004 ext. 1';
export const PRIVACY_POLICY_URL = 'https://keap.com/legal/privacy-policy/';
export const PRODUCT_DEMO_URL = 'https://keap.com/product-demo';
export const SIGN_IN_URL = 'https://signin.infusionsoft.com/login';
export const TERMS_OF_USE_URL = 'https://keap.com/legal/terms-of-use';
export const HELP_URL = 'https://keap.com/help';
export const UTM_COOKIE = 'k_utm';
export const REFERRER_COOKIE = 'k_ref';
export const GCLID_COOKIE = 'k_gclid';
export const MARKETO_COOKIE = '_mkto_trk';
export const PARTNERSTACK_COOKIE = 'k_ps';
export const PARTNER_DISCOUNT = 'partnerDiscount';
export const PARTNER_ID = 'STYXKEY_PartnerId';
export const KEAP_PRO_FREE_TRIAL_ID = '2008';
export const KEAP_PRO_TRIAL_EDITION_NAME = 'Keap Pro Free Trial';


export default LanguageCurrencyMap;
